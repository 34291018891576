import $x from '@/libs/$x'

export default {
  namespaced: true,
  state: {
    shopId: null,
    shopCode: null,
    merchantId: null,
    mrchConfigId: null,
    enableCustomerDataShare: false,
    totalAccountBalance: 0,
    totalRechargeCardBalance: 0,
    userInfo: {},
    merchantShops: [],
    shops: [],
    shopCommonSetting: {}
  },
  mutations: {
    setUserInfo: (state, data) => {
      state.userInfo = data.userinfo
      state.shops = data.shops
      state.merchantId = data.merchantConfig.merchantId
      state.mrchConfigId = data.merchantConfig.id
      state.totalAccountBalance = data.merchantConfig.totalAccountBalance
      state.totalRechargeCardBalance = data.merchantConfig.totalRechargeCardBalance
      state.enableCustomerDataShare = !!data.merchantConfig.enableCustomerDataShare
      state.merchantShops = data.merchantShops
    },
    setCommonSetting: (state, data) => {
      state.shopCommonSetting = data
    },
  },
  getters: {
    role: (state) => {
      if (state.userInfo) {
        return state.userInfo.position
      }
      return ''
    },
    currentShop: (state) => {
      let current = {}
      if (state.shopId) {
        state.shops.forEach(item => {
          if (item.id === state.shopId) {
            current = item
          }
        })
      }
      return current
    }
  },
  actions: {
    changeCustomerDataShare({
      state,
      commit,
      dispatch,
      getters
    }, val) {
      return new Promise(async (resolve, reject) => {
        try {
          const {
            data,
            code,
            message
          } = await $x.put(`/platform/${state.merchantId}/merchant/merchantConfig`, {
            id: state.mrchConfigId,
            merchantId: state.merchantId,
            enableCustomerDataShare: val ? 1 : 0
          })
          if (code === 200) {
            resolve(message)
          } else {
            reject(message)
          }
        } catch (e) {
          reject(e)
        }
      });
    },
    updateUserInfo({
      state,
      commit,
      dispatch,
      getters
    }) {
      return new Promise(async (resolve, reject) => {
        const userInfo = $x.localStorage.getItem("USER_INFO")
        if (!userInfo) {
          resolve()
          return
        }
        const {
          data,
          code,
          message
        } = await $x.post('/public/tokenuserinfo', {
          params: {
            refreshToken: userInfo.refreshToken
          }
        })
        if (code === 200) {
          if (data) {
            commit('setUserInfo', data)
            let shopCommonSetting = {}
            if (state.shopId && state.shops) {
              const currentShop = state.shops.find(item => item.id === state.shopId)
              if(currentShop){
                shopCommonSetting = currentShop.shopCommonSetting
                commit('setCommonSetting', shopCommonSetting)
              }
            }
            await dispatch('routes/initRouter', {
              shopCommonSetting,
              roleData: data.userPermission
            }, {
              root: true
            }).catch(err => {
              console.log(err)
            })
            resolve()
          }
        } else {
          reject(message)
        }
      })
    },
    //刷新token
    refreshToken({
      commit,
      dispatch,
      getters
    }) {
      return new Promise(async (resolve, reject) => {
        const userInfo = $x.localStorage.getItem('USER_INFO')
        if (userInfo) {
          const reTime = userInfo['expireTime']
          if (new Date().getTime() > reTime) {
            $x.localStorage.removeItem('USER_INFO')
            resolve()
            return
          }
          if (new Date().getTime() + 3600000 > reTime) {
            const refreshToken = userInfo['refreshToken']
            const response = await $x.post('/public/refreshtoken', {
              params: {
                refreshToken
              }
            })
            if (response && response['accessToken']) {
              const saveInfo = response
              saveInfo['expireTime'] = new Date().getTime() + saveInfo['expiryDuration']
              $x.localStorage.setItem('USER_INFO', saveInfo)
              resolve()
              return
            }
            resolve()
          }
        }
      });
    },
    //登录
    handleLogin({
      commit,
      dispatch,
      getters
    }, {
      userAccount,
      password
    }) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await $x.post('/public/signin', {
            params: {
              userAccount,
              password
            }
          })
          console.log(response)
          if (response.accessToken) {
            const userInfo = response
            userInfo['expireTime'] = new Date().getTime() + userInfo['expiryDuration']
            $x.localStorage.setItem('USER_INFO', userInfo)
            await dispatch('routes/toShopListRouter', null, {
              root: true
            })
            resolve()
          } else {
            reject(response.message)
          }
        } catch (e) {
          console.log(e)
          reject(e)
        }
      });
    },
    // handleCommonSetting({
    //   commit,
    //   dispatch,
    //   getters
    // }, {
    //   name,
    //   password,
    //   rePassword,
    //   userAccount
    // }) {
    //   return new Promise(async (resolve, reject) => {
    //     try {
    //       const {
    //         success,
    //         message
    //       } = await $x.post('/public/signup', {
    //         params: {
    //           name,
    //           password,
    //           userAccount
    //         }
    //       })
    //       console.log(success, message)
    //       if (success === true) {
    //         resolve()
    //       } else {
    //         reject(message)
    //       }
    //     } catch (err) {
    //       if (err.response.status === 400) {
    //         reject(err.response.data.message)
    //       } else if (!err.response.data.success) {
    //         reject(err.response.data.message)
    //       }
    //     }
    //   });
    // },
    //注册
    handleRegister({
      commit,
      dispatch,
      getters
    }, {
      name,
      password,
      rePassword,
      userAccount
    }) {
      return new Promise(async (resolve, reject) => {
        try {
          const {
            success,
            message
          } = await $x.post('/public/signup', {
            params: {
              name,
              password,
              userAccount
            }
          })
          console.log(success, message)
          if (success === true) {
            resolve()
          } else {
            reject(message)
          }
        } catch (err) {
          if (err.response.status === 400) {
            reject(err.response.data.message)
          } else if (!err.response.data.success) {
            reject(err.response.data.message)
          }
        }
      });
    }
  }
}
