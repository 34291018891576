import Vue from 'vue'
import $x from '../libs/$x'
import router from '../router/routerMain.js'
import main from '../views/app.vue'
import ElementUI from 'element-ui';
// import {
//   initThemeColor
// } from '../libs/themeColorClient'
import 'view-design/dist/styles/iview.css';
import store from '../store/index.js'
import vueWaves from '@/directive/waves/index' // 水波纹指令
import vueImgAlart from '@/directive/imgAlart/index' // 图片弹窗指令
import platform from '../libs/utils/platform.js'
import frequence from '../libs/utils/frequence.js'
import VueClipboard from 'vue-clipboard2'
import VueQuillEditor from 'vue-quill-editor'
import pictureDialog from '@/component/pc/pictureDialog';
import videoDialog from '@/component/pc/videoDialog';
import { Swipe, SwipeItem } from '@/component/common/vueSwiper';
import Print from 'vue-print-nb'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

require('../css/index.scss');
require('@/component/common/vueSwiper/vue-swipe.css');

// 通用组件，便于处理
Vue.prototype.$x = Vue.$x = $x;
VueClipboard.config.autoSetContainer = true;

Vue.use(vueWaves)
Vue.use(vueImgAlart)
Vue.use(ElementUI)
Vue.use(VueClipboard);
Vue.use(VueQuillEditor, { placeholder: '请输入内容' })
Vue.use(Print);
Vue.component('pictureDialog', pictureDialog);
Vue.component('videoDialog', videoDialog);
Vue.component('swipe', Swipe);
Vue.component('swipe-item', SwipeItem);

let currentDevice = ''
function getCurrentDeviceName() {
  if (platform.isPcPlatform()) {
    return 'pc'
  } else if (platform.isMobilePlatform()) {
    return 'mobile'
  } else if (platform.isTabletPlatform()) {
    return 'tablet'
  }
  return ''
}
currentDevice = getCurrentDeviceName()
function setRounder(e) {
  if (getCurrentDeviceName() !== currentDevice) {
    location.reload();
  }
}
window.addEventListener('resize', frequence.debounce(1, setRounder));

// initThemeColor()
export default new Vue({
  el: '#app',
  router,
  store,
  render: h => h(main),
});

/* IFDEBUG
window.$x = $x
IFDEBUG */
