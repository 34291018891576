var configs = {
  //本地开发环境的接口地址(npm run dev)
  dev: {
    base: `//localhost:8088/${process.env.PLATFORM_TYPE}/api/v1`,
    node_api: '//127.0.0.1:7001',
  },
  //待发布的开发环境的接口地址(npm run build-dev)
  dev_build: {
    base: '//xxx.dev61.xxx.com',
    node_api: '//xxx-node.dev61.xxx.com',
  },
  //测试环境的接口地址
  test: {
    base: `//dev.${process.env.PLATFORM_TYPE === 'ec'?'ec.':(process.env.PLATFORM_TYPE === 'pet'?'pet.':'')}mallpraise.com.cn/${process.env.PLATFORM_TYPE}/api/v1`,
    node_api: '//bbb-node-service-test.xxx.com',
  },
  //演示环境的接口地址
  demo: {
    base: '//aaa-demo.xxx.com',
    node_api: '//bbb-node-service-demo.xxx.com',
  },
  //生产环境的接口地址
  prod: {
    base: `//${process.env.PLATFORM_TYPE}.mallpraise.com.cn/${process.env.PLATFORM_TYPE}/api/v1`,
    node_api: '//bbb-node-service.xxx.com',
  }
}
module.exports = configs[process.env.ENV_CONFIG]
