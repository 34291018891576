//预览
const Container = resolve => require(['@/views/preview/normal.vue'], resolve);
//预约
const Reserve = resolve => require(['@/views/h5/reserve'], resolve);
//预约员工
const ReserveStaff = resolve => require(['@/views/h5/reserveStaff'], resolve);
//预约服务列
const ReserveService = resolve => require(['@/views/h5/reserveService'], resolve);
//预约中间页
const ReserveMiddle = resolve => require(['@/views/h5/reserveMiddle'], resolve);
//预约确认
const ReserveCheck = resolve => require(['@/views/h5/reserveCheck'], resolve);
//卡项
const Card = resolve => require(['@/views/h5/card'], resolve);
//自定义页
const CustomPage = resolve => require(['@/views/h5/customPage'], resolve);
//作品案例
const ExampleList = resolve => require(['@/views/h5/exampleList'], resolve);
//商品列表
const GoodList = resolve => require(['@/views/h5/goodList'], resolve);
//H5首页
const Home = resolve => require(['@/views/h5/home'], resolve);
//个人中心
const MyCenter = resolve => require(['@/views/h5/myCenter'], resolve);
//服务列表
const ServiceList = resolve => require(['@/views/h5/serviceList'], resolve);
//门店列表
const ShopList = resolve => require(['@/views/h5/shopList'], resolve);
//购物车
const ShoppingCart = resolve => require(['@/views/h5/shoppingCart'], resolve);
//服务详情
const ServiceDetail = resolve => require(['@/views/h5/serviceDetail'], resolve);
//卡项详情
const CardDetail = resolve => require(['@/views/h5/cardDetail'], resolve);
//商品详情
const GoodDetail = resolve => require(['@/views/h5/goodDetail'], resolve);
//商品详情
const WxLogInfo = resolve => require(['@/views/h5/wxLogInfo'], resolve);

const previewRouter = [
  {
    path: '/preview',
    meta: {
      title: '预览',
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      show: false
    },
    component: Container
  },
  {
    path: '/h5/cardDetail',
    meta: {
      title: '卡项详情',
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      show: false
    },
    component: CardDetail
  },
  {
    path: '/h5/wxLogInfo',
    meta: {
      title: '微信授权回调',
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      show: false
    },
    component: WxLogInfo
  },
  {
    path: '/h5/goodDetail',
    meta: {
      title: '产品详情',
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      show: false
    },
    component: GoodDetail
  },
  {
    path: '/h5/serviceDetail',
    meta: {
      title: '服务详情',
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      show: false
    },
    component: ServiceDetail
  },
  {
    path: '/h5/reserveStaff',
    meta: {
      title: '预约手艺人',
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      show: false
    },
    component: ReserveStaff
  },
  {
    path: '/h5/reserveCheck',
    meta: {
      title: '预约订单',
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      show: false
    },
    component: ReserveCheck
  },
  {
    path: '/h5/reserveService',
    meta: {
      title: '预约服务',
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      show: false
    },
    component: ReserveService
  },
  {
    path: '/h5/reserveMiddle',
    meta: {
      title: '预约内容',
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      show: false
    },
    component: ReserveMiddle
  },
  {
    path: '/h5/reserve',
    meta: {
      title: '预约',
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      show: false
    },
    component: Reserve
  },
  {
    path: '/h5/cardList',
    meta: {
      title: '卡项',
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      show: false
    },
    component: Card
  },
  {
    path: '/h5/custom',
    meta: {
      title: '自定义页',
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      show: false
    },
    component: CustomPage
  },
  {
    path: '/h5/exampleList',
    meta: {
      title: '作品案例',
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      show: false
    },
    component: ExampleList
  },
  {
    path: '/h5/goodList',
    meta: {
      title: '商品列表',
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      show: false
    },
    component: GoodList
  },
  {
    path: '/h5/home',
    meta: {
      title: '首页',
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      show: false
    },
    component: Home
  },
  {
    path: '/h5/myCenter',
    meta: {
      title: '个人中心',
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      show: false
    },
    component: MyCenter
  },
  {
    path: '/h5/serviceList',
    meta: {
      title: '服务列表',
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      show: false
    },
    component: ServiceList
  },
  {
    path: '/h5/shopList',
    meta: {
      title: '门店列表',
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      show: false
    },
    component: ShopList
  },
  {
    path: '/h5/shoppingCart',
    meta: {
      title: '购物车',
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      show: false
    },
    component: ShoppingCart
  }
]

export default previewRouter